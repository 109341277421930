/*
.block-listing {

	background: #eee;
	text-align: center;

	&__container {
		@extend .container;
	}

	&__content{
	//	@include make-row();
	}

	&__block {

		@include make-xs-column(12);
		@include make-sm-column(6);
		@include make-md-column(3);

		&__image {

		}
		&__title {
		}
		&__content {
		}
	}
}
 */

.header {
  padding-top: 1rem;
  padding-bottom: 1rem;

  &__container {
    @extend .container;
  }

  &__content{
    @include make-row();
    align-items: center;
  }

  &__logo-block {
    @include make-col-ready();
    @include make-col(6);

    &__link {
      text-decoration: none;
    }

    &__logo-image {

    }
  }

  &__cta-block {
    @include make-col-ready();
    @include make-col(6);
    display: flex;
    justify-content: end;

    &__button,
    &__button.btn {
      text-decoration: none;
      display: flex;
      align-items: center;

      &__icon {
        @extend .icon;
        font-size: 2rem;
      }

      &__text {
        display: none;
        @include media-breakpoint-up(md) {
          display: inline-block;
          margin-left: 1rem;
          line-height: 1.1;
          color: #3b4453;
        }
      }
    }

  }
}