.about-us-section {
  padding-top: 5rem;
  padding-bottom: 5rem;

  &__container {
    @extend .container;
  }

  &__row{
    @extend .row;
  }

  &__content {
    display: flex;
    flex-direction: column;
    justify-content: center;
    @include make-col-ready();

    @include media-breakpoint-up(sm) {
      @include make-col(12);
    }
    @include media-breakpoint-up(md) {
      @include make-col-offset(1);
    }
    @include media-breakpoint-up(lg) {
      @include make-col(5);
    }

    &__title {
    }
    &__text {
    }

  }

  &__image-group-col {
    @include make-col-ready();
    @include make-col(6);

    @include media-breakpoint-up(md) {
      @include make-col(2);
    }

    &--first-col {
      margin-top: 4rem;
      @include media-breakpoint-up(md) {
        @include make-col-offset(1);
      }
    }

  }

  &__block {
    &__icon {
      font-size: 48px;
      margin-bottom: 1rem;
    }
    &__title {
    }
    &__description {
    }
  }
}