/*
.block-listing {

	background: #eee;
	text-align: center;

	&__container {
		@extend .container;
	}

	&__content{
	//	@include make-row();
	}

	&__block {

		@include make-xs-column(12);
		@include make-sm-column(6);
		@include make-md-column(3);

		&__image {

		}
		&__title {
		}
		&__content {
		}
	}
}
 */

.footer {
  $footer-block-space-y: 3rem;

  padding: 3rem 0;
  background: #0f172a;
  color: $light;


  &__container {
    @extend .container;
  }

  &__content{
   @include make-row();
  }

  &__cta-block {
    @include make-col-ready();
    @include make-col(12);
    padding-top: $footer-block-space-y;
    padding-bottom: $footer-block-space-y;
    text-align: center;

    &__title {
      margin-bottom: 2rem;
    }
    &__content {
    }
    &__button {
      color: $light;
    }
  }

  &__logo-block {
    @include make-col-ready();
    @include make-col(8);
    @include make-col-offset(2);
    padding-top: $footer-block-space-y;
    padding-bottom: $footer-block-space-y;
    display: flex;
    justify-content: center;

    @include media-breakpoint-up(lg) {
      @include make-col(3);
      @include make-col-offset(0);
      justify-content: flex-start;
    }

    &__logo-image {
      width: 100%;
      height: auto;
      max-width: 200px;

    }
  }

  &__contact-address-block,
  &__contact-phone-block,
  &__contact-mail-block {
    @include make-col-ready();
    @include make-col(6);
    padding-top: $footer-block-space-y;
    padding-bottom: $footer-block-space-y;
    display: flex;
    gap: 1rem;
    align-items: center;
    justify-content: center;
    text-decoration: none;
    color: $primary;

    @include media-breakpoint-up(sm) {
      @include make-col(6);
    }
    @include media-breakpoint-up(lg) {
      @include make-col(3);
    }

    &__icon {
      @extend .icon;
    }
    &__title {
      font-size: 1rem;
      margin: 0;
    }

    &__description {
      font-size: 1rem;
      margin: 0;
      display: none;

      @include media-breakpoint-up(sm) {
        display: flex;
      }
    }

    &__content {
      display: flex;
      flex-direction: column;
      justify-content: center;
    }
  }

  &__contact-address-block {
    display: none;

    @include media-breakpoint-up(lg) {
     display: flex;
    }
  }

  &__meta-navigation {
    @include make-col(12);
  }

}
