.section {
  padding-top: 3rem;
  padding-bottom: 3rem;
  @include media-breakpoint-up(lg) {
    padding-top: 6rem;
    padding-bottom: 6rem;
  }

  &__container {
    @extend .container;
  }

  &__content {
    @include make-row();
  }

  .section--title {
    @include media-breakpoint-up(md) {
      text-align: center;
    }
  }
    .section--description {
      @include media-breakpoint-up(md) {
        text-align: center;
      }
  }
}