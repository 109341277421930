.service-listing {

  background: #eee;
  padding-top: 5rem;
  padding-bottom: 5rem;

  &__container {
    @extend .container;
  }

  &__row{
    @extend .row;

    // Define Columns
    @include row-cols(1);
    @include media-breakpoint-up(sm) {
      @include row-cols(2);
    }
    @include media-breakpoint-up(lg) {
      @include row-cols(3);
    }
  }

  &__block {
    &__icon {
      font-size: 48px;
      margin-bottom: 1rem;
    }
    &__title {
    }
    &__description {
    }
  }
}